import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import Swiper from 'swiper';
import {MatchHeight} from 'js-match-height';
import GLightbox from 'glightbox'

// Intiate Vuejs instance
// var app = new Vue({
//     el: '#app',
//     delimiters: ["${","}"],
//     components: {
//         Breakpoints,
//     }
// })

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    var lightbox = GLightbox();
    
    // Styling reCaptcha
    let reCaptchaContainer = document.querySelector('.g-recaptcha');
    if(reCaptchaContainer != null) {
        reCaptchaContainer.parentElement.classList.add('g-recaptcha-container');
    }
     //Match height of product names
    const el1 = new MatchHeight('.servicesLandingPage article h3');
    const el2 = new MatchHeight('.servicesLandingPage article p');

    const el3 = new MatchHeight('.projectLandingPage article h3');
    const el4 = new MatchHeight('.projectLandingPage article p.desc');

    const el5 = new MatchHeight('.blog article p');
    
    // LAZYLOAD
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    const swiperHome = new Swiper('.home-swiper', {
        slidesPerView: 1,
        grabCursor: true,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    });

    const swiperContent = new Swiper('.gallery-swiper', {
        slidesPerView: 1,
        grabCursor: true,

        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',

            renderFraction: function (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' + ' <span> of </span> ' + '<span class="' + totalClass + '"></span>'; }
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    // ACCORDIONS
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    } 

    function toggleAccordion(e) {
    
        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    // TOOGLE MOBILE MENU //
    const navTrigger = document.querySelector('.js-open-nav');
    const mobNav = document.querySelector('.mob-nav');
    const projectWrapper = document.getElementById('app');

    if(navTrigger){ 
        bindEvent(navTrigger, 'click', function() {
            navTrigger.classList.toggle('is-active');
            mobNav.classList.toggle('js-is-open');
            projectWrapper.classList.toggle('js-is-open');
        }, false);
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})